import {
  DisplayIf,
  Permissions,
  SchemaSettings_CancellationRequests,
  SchemaSettings_Endpoints_Cancellation,
  SchemaSettings_Endpoints_EndorsementQuote,
  SchemaSettings_Endpoints_Issuance,
  SchemaSettings_ProgramRating,
  RoundingMode,
} from '@policyfly/protobuf'
import { revertSchema } from '@policyfly/schema'
import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { useProtobufStore } from './protobuf'
import { loadTranslations } from '@/plugins/i18n'
import { hotReloadStore } from '@/utils/build'
import { getInputData } from '@/utils/inputData'

import type { SchemaSettings } from '@policyfly/protobuf'
import type { SettingsSchema, CancellationRequests } from '@policyfly/schema/types/schemas/settings'
import type { Formatter } from '@policyfly/utils/types/formatter'
import type { ProgramSlug } from 'types/program'

type RevertedFields =
  | 'cancellationRequests' // FormComponent + incomplete
export type State =
  Pick<Required<SettingsSchema<string>>, RevertedFields> &
  Omit<Required<SchemaSettings>, RevertedFields> &
  { slug: ProgramSlug | null }

export const useSettingsStore = defineStore({
  id: 'settings',

  state: (): State => ({
    slug: null,
    statuses: {
      application: [],
      endorsement: [],
      renewal: [],
      cancellation: [],
    },
    canAuthoriseIf: DisplayIf.create(),
    authorisationDialog: [],
    authorisationCheckbox: [],
    effectiveDatePath: 'effectiveDate',
    additionalAppFilterStatuses: [],
    roles: [{
      name: {
        oneofKind: 'programAdmin',
        programAdmin: {
          display: 'Program Admin',
          permissions: Permissions.create(),
        },
      },
    }],
    reportingPage: true,
    decimals: true,
    roundingMode: RoundingMode.ROUNDING_MODE_DOWN,
    validateInitialApp: true,
    validateAppOnLoad: false,
    restrictArchive: true,
    applicationReviewer: 'a Program Administrator',
    fab: {},
    feedEvent: {},
    multiQuoteText: 'ADD ANOTHER QUOTE',
    forceCancellationNegatives: false,
    allowMultiQuoteOnEndorsement: false,
    rating: SchemaSettings_ProgramRating.PROGRAM_RATING_NONE,
    documentManagement: false,
    endorsementCorrectionReport: false,
    cancellationRequests: false,
    formAI: false,
    formAIDocumentName: 'Application',
    hasTIV: false,
    renewsToNewPolicy: true,
    progminOnlySignatureApproval: false,
    signableDocuments: {
      approvalMessage: '',
      documents: [],
    },
    coverages: [],
    endpoints: {
      issuance: SchemaSettings_Endpoints_Issuance.ISSUANCE_TO_ISSUED,
      cancellation: SchemaSettings_Endpoints_Cancellation.CANCELLATION_QUOTE,
      endorsementQuote: SchemaSettings_Endpoints_EndorsementQuote.ENDORSEMENT_QUOTE_QUOTE_REDIRECT,
    },
    hasReinstatementWorkflow: false,
    skipAppTwoIf: DisplayIf.create(),
    issueSubmitText: 'SUBMIT',
    quoteSubmitText: {
      value: {
        oneofKind: 'string',
        string: 'SUBMIT',
      },
    },
    protobuf: false,
    banners: {
      userTips: [],
      alerts: [],
    },
    requiredIndicators: false,
    showCoverageIndicators: false,
  }),

  getters: {
    activeCoverages (): State['coverages'] {
      const coverages = this.coverages || []
      return coverages.filter((c) => getInputData(c.data!))
    },
    hasCancellationRequests (): boolean {
      return !!this.cancellationRequests
    },
    // The formatter to use for displayed currency values
    // Will use the program currency code if defined
    currencyFormatter (): Formatter {
      const protobufStore = useProtobufStore()
      return {
        name: 'customCurrency',
        params: {
          decimalPoints: 2,
          roundingMode: 'program',
          ...this.protobuf && protobufStore.policyStateWorkingCopy?.currencyInfo
            ? { prefix: 'base' }
            : { symbol: '$' },
        },
      }
    },
  },

  actions: {
    async load (): Promise<void> {
      const authenticationStore = useAuthenticationStore()
      const apiStore = useApiStore()
      const slug = authenticationStore.slug as ProgramSlug
      if (slug === this.slug) return
      const config = await apiStore.schema.settings({ slug })
      const translations = await apiStore.schema.translations({ slug })
      this.$reset()
      const settingsAssign: Partial<State> = {
        ...Object.fromEntries(
          // remove `undefined` values to allow store defaults to take priority
          Object.entries(config).filter(([, value]) => value !== undefined),
        ),
        slug,
      }
      if (config.cancellationRequests) {
        settingsAssign.cancellationRequests = revertSchema<SchemaSettings_CancellationRequests, Exclude<CancellationRequests, boolean>>(
          SchemaSettings_CancellationRequests,
          config.cancellationRequests,
        )
      }
      Object.assign(this, settingsAssign)
      loadTranslations(slug, translations)
    },
  },
})

hotReloadStore(useSettingsStore)
