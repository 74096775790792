import type { SchemaTranslations_Page } from '@policyfly/protobuf'
import type { DeepRequired } from '@policyfly/types/utils'

export const page: DeepRequired<SchemaTranslations_Page> = {
  home: {
    tasks: 'This application has 1 open task | This application has {count} open tasks',
    filter: {
      active: 'Active',
      agency: 'Agency',
      assignee: 'Assignee',
      coverholder: 'Coverholders',
      status: 'Status',
      type: 'Type',
      unreadCommentCount: 'Unread Comments',
      kind: 'Kind',
      ownedBy: 'Owner',
    },
  },
  application: {
    tabs: {
      activity: 'Activity',
      details: 'Details',
      attachments: 'Attachments',
      policy: 'Policy Documents',
      quotes: 'Quotes',
    },
  },
  submitQuotes: {
    button: {
      viewOtherMarkets: 'Market #{index} Selected',
    },
  },
  reviewQuotes: {
    button: {
      requestBind: 'Request to Bind',
      generateQuoteDocument: 'Generate Quote Document',
      preparingQuoteDocument: 'Preparing Quote Document...',
      viewQuoteDocument: 'View Quote Document',
    },
    body: {
      confirmBind: 'Please certify each step before continuing',
      confirmBindTotal: 'Total Cost',
      summaryTotal: 'Total Cost',
      facilityCount: 'facilities quoted',
      facilityName: 'Quote {index}',
      confirmDetailsTitle: 'Surplus License Details',
      confirmDetailsSubtitle: 'I certify the Surplus License details are correct',
      confirmSummaryTitle: 'Quote Summary',
      confirmSummarySubtitle: 'I certify the Quote details are correct',
    },
    notification: {
      requestedToBind: 'Your quote has been bound',
    },
  },
}
