import { Kind } from '@policyfly/protobuf'
import {
  dateToString,
  timestampToMilliseconds,
  timestampToString,
} from '@policyfly/utils/protobuf'

import { attachmentToApplicationAttachment } from './attachment'
import { policyGrpcToJson } from './policy'
import { genericStatusToApplicationStatus } from './status'
import { coreUserToUserJson } from './user'

import type { ReadonlyPolicyState } from '@/stores/protobuf'
import type { Attachment, Policy as PolicyGrpc, PolicyState } from '@policyfly/protobuf'
import type { APIApplication, ApplicationKind } from 'types/application'
import type { FeedEventLog } from 'types/events'

/**
 * @todo Wire up remaining properties
 *
 * Converts a PolicyState to an equivalent Django Application structure.
 * Used to preserve backwards compatibility for older programs.
 */
export function policyStateToApplication (policyState: PolicyState | ReadonlyPolicyState, attachments: Attachment[] = [], events: FeedEventLog[] = []): APIApplication {
  const policyModified = timestampToMilliseconds(policyState.modifiedAt)
  const latestEventDate = new Date(events?.[0]?.created || 0)
  const latestEvent = latestEventDate.getTime()

  const modified = Math.max(policyModified, latestEvent)
    ? new Date(Math.max(policyModified, latestEvent)).toISOString()
    : ''

  const kind = kindToApplicationKind(policyState.kind)
  const category = (() => {
    switch (policyState.kind) {
      case Kind.KIND_CANCELLATION: return 'CAN'
      case Kind.KIND_ENDORSEMENT: return 'END'
      case Kind.KIND_REINSTATEMENT: return 'REI'
      case Kind.KIND_RENEWAL: return 'REN'
      default: return 'NEW'
    }
  })()

  return {
    id: policyState.id,
    agency: policyState.agency as unknown as APIApplication['agency'],
    archived: false, // TODO: wire up
    assignee: coreUserToUserJson(policyState.assignee),
    // @ts-expect-error(external): Attachment in swagger doesn't match our types
    attachments: attachments.map((file) => attachmentToApplicationAttachment(file)),
    // @ts-expect-error(external): Swagger category doesn't include 'CAN'
    category,
    childID: null, // TODO: wire up
    computedData: {}, // not relevant
    created: timestampToString(policyState.createdAt)!,
    data: {}, // not relevant
    date_entered_reviewing: null, // TODO: wire up
    derivedData: null, // TODO: wire up
    diffData: {}, // not relevant
    effective: dateToString(policyState.effective),
    endorsement_iterator: Number(policyState.endorsementIterator || 0),
    events, // refreshed using the feed store
    expiration: dateToString(policyState.expiration),
    is_cancellation: policyState.kind === Kind.KIND_CANCELLATION,
    kind,
    last_modified_by: null, // TODO: wire up
    last_rating: null, // not relevant
    last_rating_error: '', // not relevant
    license_data: null, // TODO: wire up
    lock_code: null, // TODO: wire up
    lock_description: '', // TODO: wire up
    modified,
    owned_by: coreUserToUserJson(policyState.owner),
    owner_role_in_program: '', // TODO: wire up
    lastDescendantID: null, // TODO: wire up
    parentID: null, // TODO: wire up
    policy: policyGrpcToJson(policyState.policy ?? {} as PolicyGrpc, [], true) as unknown as APIApplication['policy'],
    program: policyState.program!, // TODO: wire up
    quote_set: policyState.selectedQuote ? { selected: policyState.selectedQuote.uuid4 } : {}, // TODO: wire up
    // @ts-expect-error(external): API type is wrong, it can be null
    selectedQuote: null, // TODO: wire up
    status: genericStatusToApplicationStatus(policyState.status),
    substatus: null, // TODO: wire up
    unread_comment_count: 0, // TODO: wire up
  }
}

/**
 * Converts Protobuf Kind {@link Kind}
 * to equivalent Django {@link ApplicationKind}
 */
export function kindToApplicationKind (kind: Kind): ApplicationKind {
  return kindToApplicationKindMap.get(kind) ?? 'APPLICATION'
}

const kindToApplicationKindMap = new Map<Kind, ApplicationKind>([
  [Kind.KIND_APPLICATION, 'APPLICATION'],
  [Kind.KIND_RENEWAL, 'RENEWAL'],
  [Kind.KIND_ENDORSEMENT, 'ENDORSEMENT'],
  [Kind.KIND_CANCELLATION, 'ENDORSEMENT'],
  [Kind.KIND_REINSTATEMENT, 'ENDORSEMENT'],
])

/**
 * Converts Django {@link ApplicationKind}
 * to equivalent Protobuf Kind {@link Kind}
 */
export function applicationKindToKind (kind: ApplicationKind): Kind {
  return applicationKindToKindMap.get(kind) ?? Kind.KIND_APPLICATION
}

const applicationKindToKindMap = new Map<ApplicationKind, Kind>([
  ['APPLICATION', Kind.KIND_APPLICATION],
  ['RENEWAL', Kind.KIND_RENEWAL],
  ['ENDORSEMENT', Kind.KIND_ENDORSEMENT],
])
