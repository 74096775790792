import {
  createActiveAPDVehicleScheduleComputed,
  createActiveMTCVehicleScheduleComputed,
  createActiveNTLVehicleScheduleComputed,
  createFirstYearInBusinessComputed,
  createInactiveAPDVehicleScheduleComputed,
  createInactiveMTCVehicleScheduleComputed,
  createInactiveNTLVehicleScheduleComputed,
} from '@/utils/programData'

import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    activeAPDVehicleSchedule: createActiveAPDVehicleScheduleComputed(),
    inactiveAPDVehicleSchedule: createInactiveAPDVehicleScheduleComputed(),
    activeMTCVehicleSchedule: createActiveMTCVehicleScheduleComputed(),
    inactiveMTCVehicleSchedule: createInactiveMTCVehicleScheduleComputed(),
    activeNTLVehicleSchedule: createActiveNTLVehicleScheduleComputed(),
    inactiveNTLVehicleSchedule: createInactiveNTLVehicleScheduleComputed(),
    firstYearInBusiness: createFirstYearInBusinessComputed(),
  }
}
