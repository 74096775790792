import { Task } from '@policyfly/protobuf'
import { TaskListDetail, TaskStatus } from '@policyfly/protobuf/patrick'
import {
  dateToString,
  stringToDate,
  stringToTimestamp,
  timestampToString,
} from '@policyfly/utils/protobuf'

import { userJsonToCoreUser, coreUserToUserJson } from './user'
import { TASK_STATUS_MAP } from '@/components/task/TaskStatusButton/TaskStatusButtonConstants'
import { i18n } from '@/plugins/i18n'
import { formatDate } from '@/utils/formatter'

import type { ChangelogItem } from '@/components/task/TaskDialog/TaskDialogTypes'
import type { EventEntryResponse } from '@policyfly/protobuf'
import type { ReadTaskResponse } from '@policyfly/protobuf/patrick'
import type { TaskDetail, TaskDocument } from 'types/tasks'

export function taskDocumentStatusToTaskStatus (status: TaskDocument['status']): TaskStatus {
  switch (status) {
    case 'PENDING_VERIFICATION': return TaskStatus.TASK_STATUS_PENDING_VERIFICATION
    case 'CLOSED': return TaskStatus.TASK_STATUS_CLOSED
    default: return TaskStatus.TASK_STATUS_OPEN
  }
}

export function taskStatusToTaskDocumentStatus (status: TaskStatus): TaskDocument['status'] {
  switch (status) {
    case TaskStatus.TASK_STATUS_PENDING_VERIFICATION: return 'PENDING_VERIFICATION'
    case TaskStatus.TASK_STATUS_CLOSED: return 'CLOSED'
    default: return 'OPEN'
  }
}

export function taskToTaskDetail (response: ReadTaskResponse): TaskDetail {
  const task = response.task!
  return {
    // @ts-expect-error(fixable): allow uuids for now
    id: task.id,
    policy_id: +task.policyId,
    title: task.title,
    description: task.description,
    due_date: dateToString(task.dueDate),
    assignee: task.assignee?.id ? coreUserToUserJson(task.assignee) : null,
    restricted_visibility: task.internal,
    subjectivity: task.subjectivity,
    program_id: +task.programId,
    status: task.completed ? 'CLOSED' : 'OPEN',
    created: timestampToString(task.createdAt, true) ?? '',
    created_by: coreUserToUserJson(task.createdBy)!,
    last_modified_by: coreUserToUserJson(task.lastModifiedBy)!,
    modified: timestampToString(task.modifiedAt, true) ?? '',
    archived: false,
    changelogs: [],
  }
}

export function taskDetailToTask (taskDetail: TaskDetail): Task {
  return Task.create({
    id: String(taskDetail.id),
    policyId: String(taskDetail.policy_id),
    title: taskDetail.title,
    description: taskDetail.description,
    dueDate: stringToDate(taskDetail.due_date) ?? undefined,
    assignee: taskDetail.assignee ? userJsonToCoreUser(taskDetail.assignee) : undefined,
    internal: taskDetail.restricted_visibility,
    subjectivity: taskDetail.subjectivity,
    programId: String(taskDetail.program_id),
    completed: taskDetail.status === 'CLOSED',
    createdAt: stringToTimestamp(taskDetail.created) ?? undefined,
    createdBy: userJsonToCoreUser(taskDetail.created_by),
    lastModifiedBy: userJsonToCoreUser(taskDetail.last_modified_by),
    modifiedAt: stringToTimestamp(taskDetail.modified) ?? undefined,
  })
}

export function taskDocumentToTaskListDetail (task: TaskDocument): Required<TaskListDetail> {
  return TaskListDetail.create({
    task: taskDocumentToTask(task),
    meta: {
      primaryNamedInsured: task.primary_named_insured,
      status: taskDocumentStatusToTaskStatus(task.status),
    },
  }) as Required<TaskListDetail>
}

export function taskDocumentToTask (task: TaskDocument): Task {
  return Task.create({
    id: task.id ? String(task.id) : undefined,
    title: task.title,
    description: task.description,
    dueDate: stringToDate(task.due_date) ?? undefined,
    policyId: task.policy_id ? String(task.policy_id) : undefined,
    programId: task.program_id ? String(task.program_id) : undefined,
    assignee: userJsonToCoreUser(task.assignee),
    assigneeId: task.assignee?.id ? String(task.assignee?.id) : undefined,
    internal: task.restricted_visibility,
    subjectivity: task.subjectivity,
    completed: task.status === 'CLOSED',
    createdAt: stringToTimestamp(task.created) ?? undefined,
    createdBy: task.created_by ? userJsonToCoreUser(task.created_by) : undefined,
    modifiedAt: stringToTimestamp(task.modified) ?? undefined,
    lastModifiedBy: task.last_modified_by ? userJsonToCoreUser(task.last_modified_by) : undefined,
  })
}

export function changelogToChangelogItem (changelog: TaskDetail['changelogs'][number]): ChangelogItem {
  const user = changelog.created_by
  const summary: ChangelogItem['summary'] = [{ text: `${user.first_name} ${user.last_name} `, bold: true }]
  const mappedChanges: ChangelogItem['summary'][] = Object.entries(changelog.changes)
    .map(([key, change]) => {
      switch (key) {
        case 'created':
          return [{ text: 'created this task' }]
        case 'title':
          return [{ text: 'renamed the task to ' }, { text: `'${change}'`, bold: true }]
        case 'due_date':
          return change !== 'None'
            ? [{ text: 'changed the due date to ' }, { text: formatDate(change as string), bold: true }]
            : [{ text: 'removed the due date' }]
        case 'assignee': {
          const assigneeChange = change as { id?: number, first_name: string, last_name: string }
          return assigneeChange.id
            ? [{ text: 'assigned to ' }, { text: `${assigneeChange.first_name} ${assigneeChange.last_name}`, bold: true }]
            : [{ text: 'removed the assignee' }]
        }
        case 'description':
          return [{ text: 'changed the ' }, { text: 'description', bold: true }]
        case 'subjectivity':
          return [{ text: 'changed the subjectivity to ' }, { text: change ? 'Yes' : 'No', bold: true }]
        case 'restricted_visibility':
          return [{ text: 'changed the visibility to ' }, { text: change ? 'Internal' : 'All Users', bold: true }]
        case 'status':
          return [
            { text: 'moved the task to ' },
            { text: TASK_STATUS_MAP[taskDocumentStatusToTaskStatus(change as TaskDocument['status'])].toLowerCase(), bold: true },
          ]
        default:
          return [{ text: 'made an unknown change' }]
      }
    })
  mappedChanges.forEach((change, i) => {
    if (i > 0) {
      summary.push({ text: i === mappedChanges.length - 1 ? ' and ' : ', ' })
    }
    summary.push(...change)
  })
  summary.push({ text: '.' })

  return {
    user,
    timestamp: changelog.created,
    summary,
  }
}
export function eventEntryResponseToChangelogItem (event: EventEntryResponse): ChangelogItem {
  const { userName, data, created } = event
  const name = userName.split(' ')
  const translationKey = `event.${data?.data.oneofKind}.text`
  const text = i18n.global.t(translationKey)
  return {
    user: {
      id: 0,
      first_name: name[0],
      last_name: name.length > 1 ? name[1] : '',
      email: '',
      active_at: '',
      avatar_url: '',
      isClerk: false,
      is_active: true,
    },
    summary: text === translationKey
      ? []
      : [
          { text: `${userName} `, bold: true },
          { text },
          { text: '.' },
        ],
    timestamp: timestampToString(created, true) ?? '',
  }
}
